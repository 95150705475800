import React from "react";
import { SkillBox } from "../skills/SkillsBox";

const resumeContent = [
  {
    jobPosition: `Freelance Web Developer`,
    jobType: `Health Industry`,
    jobDuration: `2016 - Present`,
    timeDuraton: `Part Time`,
    compnayName: "Aged Care Industry",
    jobDescription: `Developed the diet requirement web application to cater over 120 residents. Implemented the online feedback system to receive feedback from each meal service to produce the report and to aid with the quality assurance program. Built the online recipe application to manage all the standard recipe use in the facility.
`,
  },
];

const educatonContent = [
  {
    passingYear: "2021-2022",
    degreeTitle: "Full Stack Software Engineer",
    instituteName: "AcademyXI",
  },
  
];

const Resume = () => {
  return (
    <>
      <section id="resume" className="section">
        <div className="container">
          <div className="title">
            <h3>Experience.</h3>
          </div>
          {/* End title */}
          <div className="resume-box">
            {resumeContent.map((val, i) => (
              <div className="resume-row" key={i}>
                <div className="row">
                  <div className="col-md-4 col-xl-3">
                    <div className="rb-left">
                      <h6>{val.jobPosition}</h6>
                      <label>{val.jobType}</label>
                      <p>{val.jobDuration}</p>
                      <div className="rb-time">{val.timeDuraton}</div>
                    </div>
                  </div>
                  <div className="col-md-8 col-xl-9">
                    <div className="rb-right">
                      <h6>{val.compnayName}</h6>
                      <p>{val.jobDescription}</p>
                    </div>
                  </div>
                </div>
              </div>
              // End resume-row
            ))}
          </div>
          <p></p><p></p>
              <a className="px-btn btn-outline " href="cv-teerawat.pdf">
                    Download CV
                  </a>
          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>Education & Skills</h3>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-4 m-15px-tb">
              <ul className="aducation-box">
                {educatonContent.map((val, i) => (
                  <li key={i}>
                    <span>{val.passingYear}</span>
                    <h6>{val.degreeTitle} </h6>
                    <p>{val.instituteName}</p>
                  </li>
                ))}
              </ul>
            </div>
            {/* End .col */}

            <div className="col-lg-7 ml-auto m-15px-tb">
              <SkillBox />
            </div>
            {/* End .col */}
          
          </div>
        </div>
      </section>
    </>
  );
};

export default Resume;
