import React from "react";
import Modal from "react-modal";


Modal.setAppElement("#root");

const News = () => {
  
  return (
    <>
      <div className="row">
        <div className="col-md-6 m-15px-tb">
          <div className="blog-grid">
            <div className="blog-img">
              <a>
                <img src={`img/blog/blog1.jpg`} alt="blog post"></img>
              </a>
            </div>
            <div className="blog-info">
              <div className="meta">4-Apr-2022 by Teerawat</div>
              <h6>
                <a href = "https://teewat.medium.com/full-stack-web-application-simply-order-with-react-and-ruby-on-rails-b8314ed015be" target="_blank" rel="noreferrer">
                 Full stack web application-Simply Order with React and Ruby on Rails
                </a>
              </h6>
            </div>
          </div>
        </div>
        {/* End .col for blog-1 */}

        

        
       
      </div>
      {/* End .row */}


     
    </>
  );
};

export default News;
