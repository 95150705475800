import React from 'react'

/**
* @author
* @function SkillBox
**/

export const SkillBox = (props) => {
  return(
    <>
     <div class="row g-4">
             <div class="col-sm-6 col-lg-3">
                <div class="featured-box-icon text-primary mt-2">
                  <img
                    src="https://img.shields.io/badge/-Ruby-CC342D?logo=ruby&logoColor=white&style=for-the-badge&logoWidth=30&style=plastic"
                    height="50px"
                    width="100%"
                    alt="ruby"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="featured-box-icon text-primary mt-2">
                  <img
                    src="https://img.shields.io/badge/-Rails-CC0000?logo=rubyonrails&logoColor=white&style=for-the-badge&logoWidth=30&style=plastic"
                    height="50px"
                    width="100%"
                    alt="rails"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="featured-box-icon text-primary mt-2">
                  <img
                    src="https://img.shields.io/badge/-php-777BB4?logo=php&logoColor=white&style=for-the-badge&logoWidth=30&style=plastic"
                    height="50px"
                    width="100%"
                    alt="php"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="featured-box-icon text-primary mt-2">
                  <img
                    src="https://img.shields.io/badge/-PostgreSql-4169E1?logo=postgresql&logoColor=white&style=for-the-badge&logoWidth=30&style=plastic"
                    height="50px"
                    width="100%"
                    alt="postgresql"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="featured-box-icon text-primary mt-2">
                  <img
                    src="https://img.shields.io/badge/-Sqlite-003B57?logo=sqlite&logoColor=white&style=for-the-badge&logoWidth=30&style=plastic"
                    height="50px"
                    width="100%"
                    alt="Sqlite"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="featured-box-icon text-primary mt-2">
                  <img
                    src="https://img.shields.io/badge/-mySql-4479A1?logo=mysql&logoColor=white&style=for-the-badge&logoWidth=30&style=plastic"
                    height="50px"
                    width="100%"
                    alt="mysql"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="featured-box-icon text-primary mt-2">
                  <img
                    src="https://img.shields.io/badge/-heroku-430098?logo=heroku&logoColor=white&style=for-the-badge&logoWidth=30&style=plastic"
                    height="50px"
                    width="100%"
                    alt="heroku"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="featured-box-icon text-primary mt-2">
                  <img
                    src="https://img.shields.io/badge/-netlify-00C7B7?logo=netlify&logoColor=white&style=for-the-badge&logoWidth=30&style=plastic"
                    height="50px"
                    width="100%"
                    alt="netlify"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="featured-box-icon text-primary mt-2">
                  <img
                    src="https://img.shields.io/badge/-HTML5-E34F26?logo=html5&logoColor=white&style=for-the-badge&logoWidth=30&style=plastic"
                    height="50px"
                    width="100%"
                    alt="html5"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="featured-box-icon text-primary mt-2">
                  <img
                    src="https://img.shields.io/badge/-CSS3-1572B6?logo=css3&logoColor=white&style=for-the-badge&logoWidth=30&style=plastic"
                    height="50px"
                    width="100%"
                    alt="css3"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="featured-box-icon text-primary mt-2">
                  <img
                    src="https://img.shields.io/badge/-javascript-F7DF1E?logo=javascript&logoColor=white&style=for-the-badge&logoWidth=30&style=plastic"
                    height="50px"
                    width="100%"
                    alt="javascript"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="featured-box-icon text-primary mt-2">
                  <img
                    src="https://img.shields.io/badge/-ReactJS-61DAFB?logo=react&logoColor=white&style=for-the-badge&logoWidth=30&style=plastic"
                    height="50px"
                    width="100%"
                    alt="React"
                  />
                </div>
              </div>

              <div class="col-sm-6 col-lg-3">
                <div class="featured-box-icon text-primary mt-2">
                  <img
                    src="https://img.shields.io/badge/-mui-007FFF?logo=mui&logoColor=white&style=for-the-badge&logoWidth=30&style=plastic"
                    height="50px"
                    width="100%"
                    alt="mui"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="featured-box-icon text-primary mt-2">
                  <img
                    src="https://img.shields.io/badge/-bootstrap-7952B3?logo=bootstrap&logoColor=white&style=for-the-badge&logoWidth=30&style=plastic"
                    height="50px"
                    width="100%"
                    alt="bootstrap"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="featured-box-icon text-primary mt-2">
                  <img
                    src="https://img.shields.io/badge/-git-F05032?logo=git&logoColor=white&style=for-the-badge&logoWidth=30&style=plastic"
                    height="50px"
                    width="100%"
                    alt="git"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="featured-box-icon text-primary mt-2">
                  <img
                    src="https://img.shields.io/badge/-postman-FF6C37?logo=postman&logoColor=white&style=for-the-badge&logoWidth=30&style=plastic"
                    height="50px"
                    width="100%"
                    alt="postman"
                  />
                </div>
              </div>
              </div>
    </>
   )
  }
